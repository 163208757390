import { useContext, useEffect, useState } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import slideNovo1 from '../../assets/img/embaixadores-01.jpg';
import slideNovo2 from '../../assets/img/embaixadores-02.jpg';
import slideNovo3 from '../../assets/img/embaixadores-03.jpg';
import slideNovo4 from '../../assets/img/embaixadores-04.jpg';
import pointer from '../../assets/img/pointer.png';
import CarouselWrapper from '../../components/CarouselWrapper';
import Footer from '../../components/Footer';
import Form from '../../components/Form';
import Header from '../../components/Header';
import ParceiroComponent from '../../components/ParceiroComponent';
import { BeneficiosSection } from '../../components/v2/BeneficiosSection';
import { FaqSection } from '../../components/v2/FaqSection';
import { MensalidadeSection } from '../../components/v2/MensalidadeSection';
import { ParceirosSection } from '../../components/v2/ParceirosSection';
import { useCampaign } from '../../context/CampaignContext';
import { INITIAL_FORM_DATA, SearchFormContext } from '../../context/SearchFormDataContext';
import { Parceiro } from '../../domain/AxiosRequestParceiros';
import * as S from './style';

const INTERVAL = 3000;

let interval: NodeJS.Timer

export default function Home(){
    const { campaignData } = useCampaign();
    const { updateFormData } = useContext(SearchFormContext);
    const [parceiros, setParceiros] = useState<Parceiro[]>();
    const [progress, setProgress] = useState(1)
    const slideCounter = 4
    
    const nextFunction = (progress: number) => progress % slideCounter + 1
    const prevFunction = (progress: number) => (progress <= 1 ? slideCounter : progress - 1)

    const nextCounter = () => {
        if(interval) clearInterval(interval)
        setProgress(nextFunction)
        interval = setInterval(setProgress, 5000, nextFunction)
    }

    const prevCounter = () => {
        if(interval) clearInterval(interval)
        setProgress(prevFunction)
        interval = setInterval(setProgress, 5000, nextFunction)
    }

    useEffect(() => {
        updateFormData(INITIAL_FORM_DATA);
    }, [updateFormData])

    useEffect(() => {
        interval = setInterval(setProgress, 5000, nextFunction)

        return () => clearInterval(interval)
    }, [])

    const renderCarousels = () => campaignData.banners?.map((banner, idx) => {
        return (
            <Carousel.Item key={`banner-${idx}`} >
            <picture>
                <source srcSet={banner.img?.mobile} media="(max-width: 30em)" />
                <source srcSet={banner.img?.desktop} />
                <img src={banner.img?.desktop} alt="banner 1" />
            </picture>
            <div className='texto'>
                <h1>{banner?.title}</h1>
                <p>{banner?.subtitle}</p>
            </div>
        </Carousel.Item>
        )
    })

    return<>
        <Header />

        <S.Banner>
            <CarouselWrapper interval={INTERVAL}>
                {renderCarousels()}
            </CarouselWrapper>
        </S.Banner>

        <BeneficiosSection />

        <MensalidadeSection />

        <S.Rede>
            <h1>Aproveite a nossa rede<br />com <span>mais de 12.000 parceiros.</span></h1>
            <ParceirosSection />
            <Form setParceiros={setParceiros} />
        </S.Rede>

        {parceiros && parceiros?.length > 0
            && <S.Unidades>
                <Container>
                    <Row>
                        {parceiros?.map(parceiro => <ParceiroComponent {...parceiro} />)}
                    </Row>
                </Container>
            </S.Unidades>
        }

        <S.NossosEmbaixadores>
            <h1>Nossos embaixadores</h1>

            <S.Carousel>
                <S.CarouselImgFlowContainer>
                <S.CarouselImgFlow active={progress}>
                    <S.CarouselContainer>
                        <img src={slideNovo1} alt="" />
                        <img src={slideNovo2} alt="" />
                        <img src={slideNovo3} alt="" />
                        <img src={slideNovo4} alt="" />
                    </S.CarouselContainer>
                </S.CarouselImgFlow>
                </S.CarouselImgFlowContainer>

                <S.CarouselNext onClick={nextCounter}>
                    <img src={pointer} alt="" />
                </S.CarouselNext>

                <S.CarouselPrev onClick={prevCounter}>
                    <img src={pointer} alt="" />
                </S.CarouselPrev>

                <S.CarouselCounter>
                    1
                    <S.CarouselProgressbar total={slideCounter} progress={progress}></S.CarouselProgressbar>
                    {slideCounter}
                </S.CarouselCounter>
            </S.Carousel>
        </S.NossosEmbaixadores>

        <S.Perguntas>
            <div className="background-container">
            <picture>
                <source srcSet={campaignData.imgs.faq.bgMobile} media="(max-width: 80em)" />
                <source srcSet={campaignData.imgs.faq.bgDesktop} />
                <img src={campaignData.imgs.faq.bgDesktop} alt="banner torcida" />
            </picture>
            </div>
            <Container>
                <Row>
                    <Col md='6'>
                        <div className='texto'>
                            <h1>PERGUNTAS FREQUENTES</h1>
                        </div>
                    </Col>
                    <Col md='6'>
                    <FaqSection />
                    </Col>
                </Row>
            </Container>
            <div className="linear-gradient-decoration" />
        </S.Perguntas>
        <Footer />
    </>
}